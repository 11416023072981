<template>
  <div class="publish-detail-container">
    <div v-if="action === 'home'">
        <Title name='报价详情'></Title>
        <div class="detail-content">
            <div class="content-item detail-item">
                <div class="title">产品名称：</div>
                <div class="text">{{productInfo.productName}}</div>
            </div>
            <div class="content-item detail-item">
                <div class="title">产品类型：</div>
                <div class="text">{{productInfo.categoryName}}</div>
            </div>
            <div class="content-item detail-item">
                <div class="title">规格型号：</div>
                <div class="text">{{productInfo.productSpec}}</div>
            </div>
            <div class="content-item detail-item">
                <div class="title">数量：</div>
                <div class="text">{{productInfo.reqNum}}{{productInfo.unit}}</div>
            </div>
            <div class="content-item detail-item">
                <div class="title">最高竞买价：</div>
                <div class="text">{{productInfo.maxPrice}}元</div>
            </div>
            <div class="content-item" style="flex: 0 0 100%;">
                <div class="title">有效期：</div>
                <div class="text">{{productInfo.startTime}} 至 {{productInfo.validDate}}</div>
            </div>
            <div class="content-item" style="flex: 0 0 100%;">
                <div class="title">竞买要求：</div>
                <div class="text" v-html="productInfo.reqRemark"></div>
            </div>
        </div>
        <div class="table-content">
            <el-table
                    :header-cell-style="{background:'#F5f5f5', 'height':'22px','line-height': '22px',color:'#999'}"
                    :data="tableData"
                    style="width: 100%">
                <el-table-column
                        prop="clientName"
                        width="200"
                        label="供应商">
                        <template slot-scope="scope">
                            <span v-if="!isBuy && userInfo.enterpriseId !== scope.row.clientInfoId">{{scope.row.clientName | hideTextInfo(3, 4)}}</span>
                            <span v-else>{{scope.row.clientName}}</span>
                        </template>
                </el-table-column>
                <el-table-column
                        prop="amount"
                        width="160"
                        label="收到报价（总价）">
                        <template slot-scope="scope">
                            <span v-if="!isBuy && userInfo.enterpriseId !== scope.row.clientInfoId">{{scope.row.amount}}</span>
                            <span v-else>{{scope.row.amount}}</span>
                        </template>
                </el-table-column>
                <el-table-column
                        prop="price"
                        label="单价">
                        <template slot-scope="scope">
                            <span v-if="!isBuy && userInfo.enterpriseId !== scope.row.clientInfoId">{{scope.row.price}}</span>
                            <span v-else>{{scope.row.price}}</span>
                        </template>
                </el-table-column>
                <el-table-column
                        prop="proNum"
                        label="供货量">
                        <template slot-scope="scope">
                            <span v-if="!isBuy && userInfo.enterpriseId !== scope.row.clientInfoId">{{scope.row.proNum}}</span>
                            <span v-else>{{scope.row.proNum}}</span>
                        </template>
                </el-table-column>
                <el-table-column
                        prop="unit"
                        label="单位">
                </el-table-column>
                <el-table-column
                        fixed="right"
                        label="采购状态"
                        v-if="!viewFlag"
                >
                    <template slot-scope="scope">
                        <!-- <el-button v-if="scope.row.isBargain !== 'T' && productInfo.isMake !== 'Y'" size="small" type="text" @click="showProcurementDialog(scope.row)">确认并采购</el-button> -->
                        <span v-if="scope.row.isBargain === 'T'" style="color: blue;">已采购</span>
                        <span v-else style="color: red;">未采购</span>
                    </template>
                </el-table-column>
                <el-table-column
                        fixed="right"
                        prop="addTime"
                        width="180"
                        label="报价时间">
                </el-table-column>
            </el-table>
            <div class="pagination" v-if="pageManage.total">
                <el-pagination
                        background layout="prev, pager, next"
                        :total='pageManage.total'
                        :page-size='pageManage.limit'
                        :current-page="pageManage.page"
                        @current-change="changeCurrentPage"
                >
                </el-pagination>
            </div>
        </div>
        <el-dialog
                title="确认并采购"
                :visible.sync="procurementDialogVisible"
                width="450px">
            <div>
                <div class="content-item">
                    <div class="title">供应商：</div>
                    <div class="text">{{currentPriceInfo.clientName}}</div>
                </div>
                <div class="content-item">
                    <div class="title">产品名称：</div>
                    <div class="text">{{productInfo.productName}}</div>
                </div>
                <div class="content-item">
                    <div class="title">产品类型：</div>
                    <div class="text">{{productInfo.categoryName}}</div>
                </div>
                <div class="content-item">
                    <div class="title">规格型号：</div>
                    <div class="text">{{productInfo.productSpec}}</div>
                </div>
                <div class="content-item">
                    <div class="title">数量</div>
                    <div class="text">{{currentPriceInfo.proNum}}{{productInfo.unit}}</div>
                </div>
                <div class="content-item">
                    <div class="title">当前价格：</div>
                    <div class="text" style="color: red;">{{currentPriceInfo.price}}</div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
            <el-button size="small"  @click="procurementDialogVisible = false">取 消</el-button>
            <el-button size="small"  type="primary" @click="procurement">确认采购</el-button>
        </span>
        </el-dialog>
    </div>
      <div v-if="action === 'confirm'">
          <confirm-order :id="currentPriceInfo.id" @gohome="gohome"></confirm-order>
      </div>
  </div>
</template>

<script>

import {
    getPriceList,
    getProducDetail,
    confirmPurchase
} from '../../api';
import Title from '../../component/Title';
import confirmOrder from './ConfirmOrder';
import { hideTextInfo } from '@/utils/util';
export default {
  components: {
    Title,confirmOrder
  },
  data() {
      return {
          isBuy: this.$route.query.isBuy === "true",
          userInfo: JSON.parse(localStorage.getItem('userInfo')),
          action: 'home',
          productInfo: {},
          tableData: [],
          procurementDialogVisible: false,
          pageManage: {
            page: 1,
            limit: 10,
            total: 0
          },
          viewFlag: false,
          unit: '',
          currentPriceInfo: {} // 当前报价信息
      }
  },
  filters: {
      hideTextInfo(text, startNum, endNum) {
        return hideTextInfo(text, startNum, endNum);
      }
  },
  created() {
      this.viewFlag = this.$route.query.viewFlag
      this.unit = this.$route.query.unit

      this.getPriceList();
      this.getProducDetail();
  },
  methods: {
      gohome(){
          this.action = 'home'
          this.getPriceList()
      },
    showProcurementDialog(data) {
        this.currentPriceInfo = data;
        this.procurement()
    },
    procurement() {
        // this.procurementDialogVisible = false;
        //
        // confirmPurchase({
        //     ids: this.currentPriceInfo.id
        // }).then(res => {
        //     this.$message({
        //         type: 'success',
        //         message: '采购成功！'
        //     });
        //     this.getPriceList();
        // })
        this.procurementDialogVisible = false
        this.action = 'confirm'
    },
    getPriceList() {
        let _this = this
        getPriceList({
            ...this.pageManage,
            productId: this.$route.query.id
        }).then(res => {
            res.data.priceList.forEach(item => {
                let price = Math.round((item.amount / item.proNum * 100)) / 100;
                item.price = price.toFixed(2);
            });
            this.tableData = res.data.priceList;
            this.pageManage.total = res.data.total;
        });
    },
    getProducDetail() {
        getProducDetail({
            id: this.$route.query.id
        }).then(res => {
            this.productInfo = res.data.repProductDetail;
        });
    },
    changeCurrentPage(num){
      this.pageManage.page = num;
      this.getPriceList();
    }
  },
}
</script>

<style lang="less" >
.publish-detail-container {
    background: #fff;

    .detail-content {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
        padding: 10px 20px;
        box-sizing: border-box;

        .detail-item {
            flex: 0 0 50%;
        }
    }
    .content-item {
        display: flex;
        margin-bottom: 20px;

        .title {
            text-align-last: justify;
            width: 80px;
            font-size: 12px;
            color: #999;
            // font-weight: bold;
        }
        .text {
            position: relative;
            flex: 1;
            font-size: 14px;
            color: #333;
            // font-weight: bold;
        }
    }
    .table-content {
        padding: 10px;
        .pagination {
            padding: 20px;
            box-sizing: border-box;
            text-align: right;
        }
    }
}

</style>

<style lang="less" scoped>
    /deep/.is-leaf{
        font-weight: 400;
    }
</style>