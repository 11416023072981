var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "publish-detail-container" }, [
    _vm.action === "home"
      ? _c(
          "div",
          [
            _c("Title", { attrs: { name: "报价详情" } }),
            _c("div", { staticClass: "detail-content" }, [
              _c("div", { staticClass: "content-item detail-item" }, [
                _c("div", { staticClass: "title" }, [_vm._v("产品名称：")]),
                _c("div", { staticClass: "text" }, [
                  _vm._v(_vm._s(_vm.productInfo.productName)),
                ]),
              ]),
              _c("div", { staticClass: "content-item detail-item" }, [
                _c("div", { staticClass: "title" }, [_vm._v("产品类型：")]),
                _c("div", { staticClass: "text" }, [
                  _vm._v(_vm._s(_vm.productInfo.categoryName)),
                ]),
              ]),
              _c("div", { staticClass: "content-item detail-item" }, [
                _c("div", { staticClass: "title" }, [_vm._v("规格型号：")]),
                _c("div", { staticClass: "text" }, [
                  _vm._v(_vm._s(_vm.productInfo.productSpec)),
                ]),
              ]),
              _c("div", { staticClass: "content-item detail-item" }, [
                _c("div", { staticClass: "title" }, [_vm._v("数量：")]),
                _c("div", { staticClass: "text" }, [
                  _vm._v(
                    _vm._s(_vm.productInfo.reqNum) +
                      _vm._s(_vm.productInfo.unit)
                  ),
                ]),
              ]),
              _c("div", { staticClass: "content-item detail-item" }, [
                _c("div", { staticClass: "title" }, [_vm._v("最高竞买价：")]),
                _c("div", { staticClass: "text" }, [
                  _vm._v(_vm._s(_vm.productInfo.maxPrice) + "元"),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass: "content-item",
                  staticStyle: { flex: "0 0 100%" },
                },
                [
                  _c("div", { staticClass: "title" }, [_vm._v("有效期：")]),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(
                      _vm._s(_vm.productInfo.startTime) +
                        " 至 " +
                        _vm._s(_vm.productInfo.validDate)
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "content-item",
                  staticStyle: { flex: "0 0 100%" },
                },
                [
                  _c("div", { staticClass: "title" }, [_vm._v("竞买要求：")]),
                  _c("div", {
                    staticClass: "text",
                    domProps: { innerHTML: _vm._s(_vm.productInfo.reqRemark) },
                  }),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "table-content" },
              [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      "header-cell-style": {
                        background: "#F5f5f5",
                        height: "22px",
                        "line-height": "22px",
                        color: "#999",
                      },
                      data: _vm.tableData,
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "clientName",
                        width: "200",
                        label: "供应商",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                !_vm.isBuy &&
                                _vm.userInfo.enterpriseId !==
                                  scope.row.clientInfoId
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("hideTextInfo")(
                                            scope.row.clientName,
                                            3,
                                            4
                                          )
                                        )
                                      ),
                                    ])
                                  : _c("span", [
                                      _vm._v(_vm._s(scope.row.clientName)),
                                    ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1731600950
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "amount",
                        width: "160",
                        label: "收到报价（总价）",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                !_vm.isBuy &&
                                _vm.userInfo.enterpriseId !==
                                  scope.row.clientInfoId
                                  ? _c("span", [
                                      _vm._v(_vm._s(scope.row.amount)),
                                    ])
                                  : _c("span", [
                                      _vm._v(_vm._s(scope.row.amount)),
                                    ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2705567771
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "price", label: "单价" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                !_vm.isBuy &&
                                _vm.userInfo.enterpriseId !==
                                  scope.row.clientInfoId
                                  ? _c("span", [
                                      _vm._v(_vm._s(scope.row.price)),
                                    ])
                                  : _c("span", [
                                      _vm._v(_vm._s(scope.row.price)),
                                    ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2359855931
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "proNum", label: "供货量" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                !_vm.isBuy &&
                                _vm.userInfo.enterpriseId !==
                                  scope.row.clientInfoId
                                  ? _c("span", [
                                      _vm._v(_vm._s(scope.row.proNum)),
                                    ])
                                  : _c("span", [
                                      _vm._v(_vm._s(scope.row.proNum)),
                                    ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2797050523
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "unit", label: "单位" },
                    }),
                    !_vm.viewFlag
                      ? _c("el-table-column", {
                          attrs: { fixed: "right", label: "采购状态" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    scope.row.isBargain === "T"
                                      ? _c(
                                          "span",
                                          { staticStyle: { color: "blue" } },
                                          [_vm._v("已采购")]
                                        )
                                      : _c(
                                          "span",
                                          { staticStyle: { color: "red" } },
                                          [_vm._v("未采购")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            4245147722
                          ),
                        })
                      : _vm._e(),
                    _c("el-table-column", {
                      attrs: {
                        fixed: "right",
                        prop: "addTime",
                        width: "180",
                        label: "报价时间",
                      },
                    }),
                  ],
                  1
                ),
                _vm.pageManage.total
                  ? _c(
                      "div",
                      { staticClass: "pagination" },
                      [
                        _c("el-pagination", {
                          attrs: {
                            background: "",
                            layout: "prev, pager, next",
                            total: _vm.pageManage.total,
                            "page-size": _vm.pageManage.limit,
                            "current-page": _vm.pageManage.page,
                          },
                          on: { "current-change": _vm.changeCurrentPage },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: "确认并采购",
                  visible: _vm.procurementDialogVisible,
                  width: "450px",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.procurementDialogVisible = $event
                  },
                },
              },
              [
                _c("div", [
                  _c("div", { staticClass: "content-item" }, [
                    _c("div", { staticClass: "title" }, [_vm._v("供应商：")]),
                    _c("div", { staticClass: "text" }, [
                      _vm._v(_vm._s(_vm.currentPriceInfo.clientName)),
                    ]),
                  ]),
                  _c("div", { staticClass: "content-item" }, [
                    _c("div", { staticClass: "title" }, [_vm._v("产品名称：")]),
                    _c("div", { staticClass: "text" }, [
                      _vm._v(_vm._s(_vm.productInfo.productName)),
                    ]),
                  ]),
                  _c("div", { staticClass: "content-item" }, [
                    _c("div", { staticClass: "title" }, [_vm._v("产品类型：")]),
                    _c("div", { staticClass: "text" }, [
                      _vm._v(_vm._s(_vm.productInfo.categoryName)),
                    ]),
                  ]),
                  _c("div", { staticClass: "content-item" }, [
                    _c("div", { staticClass: "title" }, [_vm._v("规格型号：")]),
                    _c("div", { staticClass: "text" }, [
                      _vm._v(_vm._s(_vm.productInfo.productSpec)),
                    ]),
                  ]),
                  _c("div", { staticClass: "content-item" }, [
                    _c("div", { staticClass: "title" }, [_vm._v("数量")]),
                    _c("div", { staticClass: "text" }, [
                      _vm._v(
                        _vm._s(_vm.currentPriceInfo.proNum) +
                          _vm._s(_vm.productInfo.unit)
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "content-item" }, [
                    _c("div", { staticClass: "title" }, [_vm._v("当前价格：")]),
                    _c(
                      "div",
                      { staticClass: "text", staticStyle: { color: "red" } },
                      [_vm._v(_vm._s(_vm.currentPriceInfo.price))]
                    ),
                  ]),
                ]),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small" },
                        on: {
                          click: function ($event) {
                            _vm.procurementDialogVisible = false
                          },
                        },
                      },
                      [_vm._v("取 消")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: { click: _vm.procurement },
                      },
                      [_vm._v("确认采购")]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.action === "confirm"
      ? _c(
          "div",
          [
            _c("confirm-order", {
              attrs: { id: _vm.currentPriceInfo.id },
              on: { gohome: _vm.gohome },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }